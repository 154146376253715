import { VStack } from "#style/jsx";
import { styled } from "#style/jsx";
import { css } from "#style/css";
import { useTranslation } from "#root/domain/i18n";

import { Logo } from "./Logo";
import { Typography } from "./Typography";
import { SectionContainer } from "./SectionContainer";
import { Link } from "./Link";

export function Footer() {
  const { t } = useTranslation();

  return (
    <SectionContainer
      as="footer"
      rootStyle={css({
        backgroundColor: "darkGray",
      })}
      innerStyle={css({
        flexDirection: "column",
        alignItems: "center",
        gap: "10",
        color: "white",
        lg: {
          flexDirection: "row",
          justifyContent: "space-between",
        },
      })}
    >
      <Link href="/" aria-label={t("common.footer.logoLabel")}>
        <Logo size="lg" color="light" />
      </Link>

      <VStack flex={1} w="full" lg={{ alignItems: "flex-end" }}>
        <styled.ul
          display="flex"
          flexDir="column"
          justifyContent="flex-start"
          alignItems="center"
          textDecoration="underline"
          lg={{
            alignItems: "flex-end",
          }}
        >
          <Typography tag="li" textStyle="small">
            <Link href="/privacy">{t("common.footer.privacyPolicy")}</Link>
          </Typography>
          <Typography tag="li" textStyle="small">
            <Link href="/terms">{t("common.footer.tos")}</Link>
          </Typography>
          <Typography tag="li" textStyle="small">
            <a href="mailto:asklive@christopher2k.dev">
              {t("common.footer.contactAndSupport")}
            </a>
          </Typography>
        </styled.ul>
        <Typography textStyle="small" class={css({ textAlign: "center" })}>
          {t("common.footer.copyright")}
        </Typography>
      </VStack>
    </SectionContainer>
  );
}
