import { styled } from "#style/jsx";

import { Logo } from "./Logo";
import { Button } from "./Button";
import { Link } from "./Link";
import { useTranslation } from "#root/domain/i18n";

export interface NavBarProps {}

export function NavBar(_props: NavBarProps) {
  const { t } = useTranslation();

  return (
    <styled.nav
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      py="8"
      w="full"
      maxWidth="contentWidth"
      mx="auto"
    >
      <Link href="/" aria-label={t("common.navbar.logoLabel")}>
        <Logo />
      </Link>

      <styled.ul
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        gap="6"
      >
        <li>
          <Button tag="a" href="/login" color="darkGray" ctx="dark">
            {t("common.navbar.getStarted")}
          </Button>
        </li>
      </styled.ul>
    </styled.nav>
  );
}
