import { mergeProps, type ParentProps } from "solid-js";

import { Box } from "#style/jsx";

export type SectionContainerProps = ParentProps<{
  rootStyle?: string;
  innerStyle?: string;
  as?: string;
}>;

export function SectionContainer(_props: SectionContainerProps) {
  const props = mergeProps({ as: "section" }, _props);

  return (
    <Box w="full" px="8" class={props.rootStyle}>
      <Box
        // @ts-expect-error
        as={props.as}
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        w="full"
        maxW="contentWidth"
        py="28"
        mx="auto"
        class={props.innerStyle}
      >
        {props.children}
      </Box>
    </Box>
  );
}
